<template>
  <div class="left-section">
    <div class="d-flex flex-column pt-5" style="width: 320px;">
      <span class="white--text font-20 app-bold-font mb-5">Search results</span>
      <search-list-card
        :title="category.title"
        :image="category.image"
        :image_selected="category.image_selected"
        v-for="category in categories"
        :key="category.title"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import SearchListCard from "./SearchListCard.vue";
export default {
  components: { SearchListCard },
  data() {
    return {
      categories: [
        {
          title: "All",
          image: require("@/assets/svg/search/icon_all.svg"),
          image_selected: require("@/assets/svg/search/icon_all_selected.svg")
        },
        {
          title: "Volunteer",
          image: require("@/assets/svg/search/icon_volunteer.svg"),
          image_selected: require("@/assets/svg/search/icon_volunteer_selected.svg")
        },
        {
          title: "Agency",
          image: require("@/assets/svg/search/icon_agency.svg"),
          image_selected: require("@/assets/svg/search/icon_agency_selected.svg")
        },
        // {
        //   title: "Company",
        //   image: require("@/assets/svg/search/icon_agency.svg"),
        //   image_selected: require("@/assets/svg/search/icon_agency_selected.svg")
        // },
        {
          title: "Needs",
          image: require("@/assets/svg/search/icon_needs.svg"),
          image_selected: require("@/assets/svg/search/icon_needs_selected.svg")
        },
        {
          title: "Events",
          image: require("@/assets/svg/search/icon_events.svg"),
          image_selected: require("@/assets/svg/search/icon_events_selected.svg")
        },
        {
          title: "Blogs",
          image: require("@/assets/svg/search/icon_blogs.svg"),
          image_selected: require("@/assets/svg/search/icon_blogs_selected.svg")
        }
      ]
    };
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      sideSection: "sideSection"
    })
  },
  methods: {
    ...mapActions("auth", {
      getLeftSectionInfo: "getLeftSectionInfo"
    }),
    goToVolunteers() {
      if (this.$route.name != "agency-volunteers") {
        this.$router.push({ name: "agency-volunteers" });
      }
    },
    goToStore() {
      if (this.$route.name != "agency-shop") {
        this.$router.push({ name: "agency-shop" });
      }
    },
    goToAgencies() {
      if (this.$route.name != "agencies") {
        this.$router.push({ name: "agencies" });
      }
    }
  },
  mounted() {
    this.loading = true;
    this.getLeftSectionInfo()
      .then(() => {
        this.loading = false;
      })
      .catch(error => {
        console.log(error.response.data.message);
      });
  }
};
</script>
<style scoped>
.left-section {
  max-height: 0;
  overflow-y: hidden;
  position: sticky;
  min-height: inherit;
  overflow-x: hidden;
  top: 56px;
  max-width: 430px;
  min-width: 430px;
  width: 430px;
  padding-left: 100px;
}
.left-section:hover {
  overflow-y: auto;
}
.left-section::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

/* Track */
.left-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.left-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.left-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
